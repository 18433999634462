import { PropList } from '../../../core/models/models';
import { FitnesSteps } from './fitness.quiz';
import { GeneticsSteps } from './genetics.quiz';
import { NutritionSteps } from './nutrition.quiz';
import { FollowUpSteps, SemaglutideTirzepatideSteps } from './weightloss.quiz';

export const SEM_12_KICKSTART_MED_ID = '7VotoD7xG9LVD0w89GJUrJ3YSLsJMxpn';
export const TER_12_KICKSTART_MED_ID = 'LjO52Yr77oAadftadDs28vbYKvb7LJXn';
export const WEIGHTLOSS_QUIZ_TYPE = 1;
export const NUTRITION_QUIZ_TYPE = 3;
export const FITNESS_QUIZ_TYPE = 4;
export const FOLLOWUP_QUIZ_TYPE = 5;
export const GENETICS_QUIZ_TYPE = 6;

export const registrationFields = [
  'first_name',
  'last_name',
  'middle_name',
  'email',
  'dob',
  'sex',
  'phone',
  'id',
  'temp_token',
  'email_verified',
  'phone_verified',
  'relationship_list',
  'role',
  'zip_code',
  'address_1',
  'address_2',
  'age',
  'appeal',
  'city',
  'marital_status',
  'occupation',
  'state',
  'photo_id_exists',
  'photo_exists'
];
export function getQuizType(): PropList {
  return {
    1: { text: 'Weight loss', title: 'Semaglutide/Tirzepatide' },
    2: {
      text: 'Weight loss',
      title: 'Sermorelin'
    },
    3: {
      text: 'Nutrition'
    },
    4: {
      text: 'Fitness'
    },
    5: {
      text: 'Follow Up'
    },
    6: {
      text: 'Genetics'
    }
  };
}
export const contactFormStep = {
  id: 1,
  step_id: 2,
  registration_step: true,
  form_id: 4,
  with_proggress: {
    step: 1
  }
};
export function productFormStep(title: string) {
  return {
    title,
    id: 2,
    step_id: 3,
    not_selectable: true,
    no_content: true,
    form_id: 8,
    with_proggress: {
      step: 2
    }
  };
}
export function paymentForm(step: number) {
  return {
    no_content: true,
    id: step,
    step_id: null,
    checkout_step: true,
    end_step: true,
    form_id: 11
  };
}
export function optionsYesNoWithText(nextStep: number, reason = true) {
  return [
    {
      title: 'Yes',
      step_id: nextStep,
      id: 1,
      user_value: reason
    },
    {
      title: 'No',
      step_id: nextStep,
      id: 2
    }
  ];
}
export function optionsYesNoWithSometimes(nextStep: number) {
  return [
    {
      title: 'Yes',
      step_id: nextStep,
      id: 1
    },
    {
      title: 'No',
      step_id: nextStep,
      id: 2
    },
    {
      title: 'Sometimes',
      step_id: nextStep,
      id: 3
    }
  ];
}
export function estimateOptions(nextStep: number) {
  return [
    {
      title: '1',
      step_id: nextStep,
      id: 1
    },
    {
      title: '2',
      step_id: nextStep,
      id: 2
    },
    {
      title: '3',
      step_id: nextStep,
      id: 3
    },
    {
      title: '4',
      step_id: nextStep,
      id: 4
    },
    {
      title: '5',
      step_id: nextStep,
      id: 5
    }
  ];
}
export function quizTypes(
  product: any,
  user: any,
  type: number,
  isLoggedIn: boolean,
  isEdit?: boolean
): any {
  switch (type) {
    case WEIGHTLOSS_QUIZ_TYPE:
      return SemaglutideTirzepatideSteps(user, isEdit, product);
    case NUTRITION_QUIZ_TYPE:
      return NutritionSteps(user, isLoggedIn, isEdit);
    case FITNESS_QUIZ_TYPE:
      return FitnesSteps(user, isLoggedIn, isEdit);
    case FOLLOWUP_QUIZ_TYPE:
      return FollowUpSteps(product);
    case GENETICS_QUIZ_TYPE:
      return GeneticsSteps(user, isLoggedIn, isEdit);
    default:
      return [];
  }
}

function reqistrationStep(type: number, withProductSelection?: boolean) {
  if (type === WEIGHTLOSS_QUIZ_TYPE) {
    if (withProductSelection) {
      return [
        {
          id: 1,
          title: 'Sign up',
          current_step: 1
        },
        {
          id: 2,
          title: 'Select options',
          current_step: 18
        },
        {
          id: 3,
          title: 'Select product',
          current_step: 3
        },
        {
          id: 4,
          title: 'Select plan',
          current_step: 4
        },
        {
          id: 5,
          title: 'Intake form',
          current_step: 5
        }
      ];
    } else {
      return [
        {
          id: 1,
          title: 'Sign up',
          current_step: 1
        },
        {
          id: 2,
          title: 'Select options',
          current_step: 18
        },
        {
          id: 4,
          title: 'Select plan',
          current_step: 4
        },
        {
          id: 5,
          title: 'Intake form',
          current_step: 5
        }
      ];
    }
  } else if (type === GENETICS_QUIZ_TYPE) {
    return [
      {
        id: 1,
        title: 'Sign up',
        current_step: 1
      },
      {
        id: 2,
        title: 'Demographic information',
        current_step: 2
      }
    ];
  } else {
    return [
      {
        id: 1,
        title: 'Sign up',
        current_step: 1
      },
      {
        id: 2,
        title: 'Select product',
        current_step: 2
      },
      {
        id: 3,
        title: 'Intake form',
        current_step: 3
      }
    ];
  }
}

function registrationStepSales() {
  return [
    {
      id: 1,
      title: 'Sign up',
      current_step: 100
    },
    {
      id: 4,
      title: 'Verify',
      prev_step: 1,
      current_step: 4
    }
  ];
}

export function getReqistrationStep(
  type: number,
  withProductSelection: boolean,
  isSaleRegistered?: boolean
) {
  if (isSaleRegistered) {
    return registrationStepSales();
  } else {
    return reqistrationStep(type, withProductSelection);
  }
}
