export const USER_ROLE_TYPE = 1;
export const SALES_ROLE_TYPE = 2;
export const ADMIN_ROLE_TYPE = 3;
export const AFFILIATE_ROLE_TYPE = 4;

const salesMenu = [
  {
    title: 'My Dashboard',
    image: 'menu-icon-1.svg',
    link: '/profile/my-dashboard'
  },
  {
    title: 'Patients',
    image: 'menu-icon-5.svg',
    link: '/profile/patients'
  }
];

const userMenu = [
  {
    title: 'My Dashboard',
    image: 'menu-icon-1.svg',
    link: '/profile/my-dashboard'
  },
  // {
  //   title: 'My Test Kits',
  //   image: 'menu-icon-2.svg',
  //   link: '/profile/my-test-kits'
  // },
  {
    title: 'Bloodtests',
    image: 'menu-icon-2.svg',
    link: '/profile/my-orders'
  },
  {
    title: 'Weightloss',
    image: 'menu-icon-4.svg',
    link: '/profile/my-medications'
  },
  {
    title: 'Genetic Test',
    image: 'menu-icon-2.svg',
    link: '/profile/my-genetics'
  },
  {
    title: 'Nutrition',
    image: 'menu-icon-3.svg',
    link: '/profile/my-nutrition'
  },
  {
    title: 'Personalized Fitness',
    image: 'menu-icon-4.svg',
    link: '/profile/my-fitness'
  },
  //   {
  //     title: 'My Payment Method',
  //     image: 'menu-icon-5.svg',
  //     link: '/profile/my-payment-method'
  //   },
  {
    title: 'My Addresses',
    image: 'menu-icon-6.svg',
    link: '/profile/my-addresses'
  },
  {
    title: 'My Questionnaire',
    image: 'menu-icon-2.svg',
    link: '/profile/my-questionnaire'
  }
];

const affilateMenu = [
  {
    title: 'My Account',
    image: 'menu-icon-1.svg',
    link: '/profile/my-account'
  },
  {
    title: 'My Payouts',
    image: 'menu-icon-5.svg',
    link: '/profile/my-payouts'
  },
  {
    title: 'My Dashboard',
    image: 'menu-icon-1.svg',
    link: '/profile/my-referrals'
  },
  {
    title: 'Affiliates History',
    image: 'menu-icon-3.svg',
    link: '/profile/my-affiliates'
  },
  {
    title: 'Bonus Grid',
    image: 'menu-icon-5.svg',
    link: '/profile/my-bonus'
  }
];

export function profileMenu(role: number) {
  if (role === SALES_ROLE_TYPE) {
    return salesMenu;
  } else if (role === USER_ROLE_TYPE || role === ADMIN_ROLE_TYPE) {
    return userMenu;
  } else if (role === AFFILIATE_ROLE_TYPE) {
    return affilateMenu;
  } else {
    return [];
  }
}
