import { SALES_ROLE_TYPE } from '../../profile/common/profile.config';
import { optionsYesNoWithText, paymentForm } from './quiz';

export const forbiddenTitle =
  'Unfortunately, you are not suitable for treatment with GLP-1 medication';
export const forbiddenTreatmentDisclaimer =
  'Your suitability for treatment with this medication is the opinion of the physician who created our medical intake form and does not necessarily represent the opinion of all physicians nor the medical community as a whole.';

export function forbiddenIndexBMI(bmi: number) {
  return {
    title: forbiddenTitle,
    text: `Given that your Body Mass Index (BMI) is below ${bmi} or lower you are not suitable for treatment with GLP-1 receptor agonists (Example: Semaglutide, Tirzepatide, ect). Your safety is our number one priority.`,
    text_2: forbiddenTreatmentDisclaimer
  };
}

const conditionsSystemOptions = [
  {
    title: 'Alcohol overuse',
    id: 1
  },
  {
    title: 'Admitted to a psychiatric facility',
    id: 2
  },
  {
    title: 'Attempted suicide',
    step_id: null,
    forbidden: 1,
    content: {
      title: forbiddenTitle,
      text: 'Based on your responses to this question you are not suitable for treatment with GLP-1 receptor agonsits (Example: Semaglutide, Tirzepatide, ect). Your safety is our number one priority.',
      text_2: forbiddenTreatmentDisclaimer
    }
  },
  {
    title: 'Acute Gallbladder Disease without the removal of your gallbladder',
    step_id: null,
    forbidden: 1,
    content: {
      title: forbiddenTitle,
      text: 'Given that you have a personal history of acute gallbladder disease without the removal of your gallbladder, you are not suitable for treatment with GLP-1 receptor agonsists (Example: Semaglutide, Tirzepatide, ect). Your safety is our number one priority and these medications are associated with a higher risk of gallbladder disease.',
      text_2: forbiddenTreatmentDisclaimer
    }
  },
  {
    title: 'Gastroparesis or Bowel Obstruction',
    step_id: null,
    forbidden: 1,
    content: {
      title: forbiddenTitle,
      text: 'Given that you have a personal history of gastroparesis or bowel obstruction, you are not suitable for treatment with GLP-1 receptor agonsists (Example: Semaglutide, Tirzepatide, ect). Your safety is our number one priority and these medications may cause an increased risk of stomach paralysis and bowel obstruction.',
      text_2: forbiddenTreatmentDisclaimer
    }
  },
  {
    title: 'Bariatric surgery',
    id: 4
  },
  {
    title: 'Bipolar disorder',
    id: 5
  },
  {
    title: 'Bleeding or clotting disorder',
    id: 6
  },
  {
    title: 'Chronic kidney (renal) failure',
    id: 7
  },
  {
    title: 'Liver disease',
    id: 8
  },
  {
    title: 'Depression',
    id: 9
  },
  {
    title: 'Diabetes',
    id: 10
  },
  {
    title: 'Illicit Drug use',
    id: 11
  },
  {
    title: 'Eating disorders (anorexia/bulimia)',
    id: 12
  },
  {
    title: 'Gallbladder disease',
    id: 13
  },
  {
    title: 'Heart attack',
    id: 14
  },
  {
    title: 'Heart disease',
    id: 15
  },
  {
    title: 'High blood pressure',
    id: 16
  },
  {
    title: 'High cholesterol',
    id: 17
  },
  {
    title: 'Infection/tumor in your brain or spinal cord',
    id: 18
  },
  {
    title: 'Low blood sugar (hypoglycemia)',
    id: 19
  },
  {
    title: 'Mental illness',
    id: 20
  },
  {
    title: 'Pancreatitis',
    step_id: null,
    forbidden: 1,
    content: {
      title: forbiddenTitle,
      text: 'Based on your history of pancreatitis, you are not suitable for treatment with GLP-1 receptor agonsits (Example: Semaglutide, Tirzepatide, ect). Your safety is our number one priority and these medications have been associated, though rarely, with an increased risk of pancreatitis. In addition, the symptoms you might experience from the medication, such as abdominal pain or nausea, could mask or be confused with a pancreatitis flare-up.',
      text_2: forbiddenTreatmentDisclaimer
    }
  },
  {
    title:
      'A personal or family history of medullary thyroid carcinoma or multiple endocrine neoplasia syndrome type 2',
    step_id: null,
    forbidden: 1,
    content: {
      title: forbiddenTitle,
      text: 'Given that you or anyone in your family has a history of medullary thyroid cancer or multiple neoplasia syndrome Type 2 (MEN 2), you are not suitable for treatment with GLP-1 receptor agonsists (Example: Semaglutide, Tirzepatide, ect). Your safety is our number one priority and these medications should be avoided since it is not know if they can increase the risk of thyroid tumors or medullary thyroid cancer.',
      text_2: forbiddenTreatmentDisclaimer
    }
  },
  {
    title: 'Recently thoughts of hurting yourself or others',
    id: 23
  },
  {
    title: 'Seizures',
    id: 24
  },
  {
    title: 'Sleep apnea',
    id: 25
  },
  {
    title: 'Stroke',
    id: 26
  },
  {
    title: 'Thyroid dysfunction',
    id: 27
  },
  {
    title: 'Any other conditions',
    user_value: true,
    id: 28
  },
  {
    title: 'None of the above',
    id: 29,
    none_selected: true
  }
];

const agreeOptions = [
  {
    title: 'I have read and understand the above linked information',
    sub_title:
      '<p>Medical Weight Loss is a supplement to a healthy diet and exercise program. Sustainable weight loss takes time and weight loss beyond 1 – 1.5 pounds per week can present health risks. <a href="page/consumer_bill_of_rights" class="text-underline link-hover" target="_blank">Medical Weight Loss Bill of Rights</a> and <a href="page/important_safety_information" class="text-underline link-hover" target="_blank">Important Safety Information</a></p>',
    required: true,
    step_id: 23,
    id: 1
  },
  {
    title: 'I accept the above linked Medical Consent and Terms and Conditions',
    sub_title:
      '<p>Please be aware, GLP-1 Weight Management Medications (I.E. Semaglutide/Tirzepatide) are prescription medications, so you agree to only receive treatment with these medications from one provider / pharmacy at a time. Please make sure you follow your provider’s instructions exactly. <a href="page/medical_consent" class="text-underline link-hover" target="_blank">Medical Consent</a>, <a href="page/terms_and_conditions" class="text-underline link-hover" target="_blank">Terms and Conditions</a></p>',
    step_id: 23,
    required: true,
    id: 2
  },
  {
    title:
      'I accept the above linked Telemedicine Informed Consent and Privacy Policy',
    sub_title:
      '<p>I agree to the Providers <a href="/page/asynchronous_telemedicine_informed_consent" class="link-hover text-underline" target="_blank"> Telemedicine Informed Consent</a> and <a href="/page/privacy_policy_beluga" class="link-hover text-underline" target="_blank">Privacy Policy</a></p>',
    step_id: 23,
    required: true,
    id: 3
  }
];

export const systemRequiredSteps = [1, 2, 3, 9, 10, 11, 12, 13, 14];

function dosesSteps(user?: any, product?: any) {
  return [
    {
      title:
        'Are you currently taking GLP-1 medications (i.e. semaglutide, tirzepatide or another glp-1)?',
      id: 18,
      system: true,
      with_proggress: {
        step: 2
      },
      list: [
        {
          title: 'Yes',
          weight_loss_patient: true,
          step_id: 201,
          disabled: user?.not_filled,
          id: 1
        },
        {
          title: 'No',
          disabled: user?.not_filled,
          step_id: 2,
          id: 2,
          steps_remove: [201, 202, 203, 204, 205, 206, 19, 208, 209]
        }
      ],
      form_id: 12
    },
    {
      title:
        'Are you currently taking Semaglutide, Tirzepatide or Another GLP-1?',
      id: 201,
      system: true,
      with_proggress: {
        step: 2
      },
      list: [
        {
          title: 'Semaglutide',
          step_id: 202,
          disabled: user?.not_filled,
          steps_remove: [203, 19],
          doses: 1,
          id: 1
        },
        {
          title: 'Tirzepatide',
          step_id: 203,
          steps_remove: [202, 19],
          disabled: user?.not_filled,
          doses: 2,
          id: 2
        },
        {
          title: 'Other',
          step_id: 19,
          disabled: user?.not_filled,
          steps_remove: [202, 203, 204, 205, 206, 208, 209],
          id: 3
        }
      ],
      form_id: 12
    },
    {
      title: 'Which Semaglutide dose do you currently use?',
      id: 202,
      step_id: 204,
      steps_remove: [204],
      doses: 1,
      not_filtered: true,
      system: true,
      form_id: 24,
      with_proggress: {
        step: 2
      },
      content: {
        body: '(if your dose is not listed, please pick the dosage that is closest to your current dose)'
      }
    },
    {
      title: 'Which Tirzepatide dose do you currently use?',
      id: 203,
      step_id: 204,
      steps_remove: [204],
      doses: 2,
      not_filtered: true,
      system: true,
      form_id: 24,
      with_proggress: {
        step: 2
      },
      content: {
        body: '(if your dose is not listed, please pick the dosage that is closest to your current dose)'
      }
    },
    {
      title:
        'Do you prefer to stay at the same dose, decrease your dose, increase your dose or change the medication?',
      id: 204,
      system: true,
      with_proggress: {
        step: 2
      },
      form_id: 16
    },
    {
      title: 'Please choose the lower dosage you prefer:',
      id: 205,
      step_id: 3,
      doses: null,
      system: true,
      not_filtered: false,
      with_proggress: {
        step: 2
      },
      form_id: 24
    },
    {
      title: 'Please select the medication',
      id: 206,
      system: true,
      with_proggress: {
        step: 2
      },
      list: [
        {
          title: 'Semaglutide',
          step_id: 208,
          disabled: user?.not_filled || product?.changed_med_dose === 1,
          doses: 1,
          id: 1
        },
        {
          title: 'Tirzepatide',
          step_id: 209,
          disabled: user?.not_filled || product?.changed_med_dose === 2,
          doses: 2,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title: 'What is your requested dose?',
      id: 208,
      step_id: 3,
      steps_remove: [205],
      doses: 1,
      system: true,
      not_filtered: true,
      with_proggress: {
        step: 2
      },
      form_id: 24
    },
    {
      title: 'What is your requested dose?',
      id: 209,
      step_id: 3,
      steps_remove: [205],
      system: true,
      doses: 2,
      not_filtered: true,
      with_proggress: {
        step: 2
      },
      form_id: 24
    },
    {
      title: 'Which medication and which dose?',
      id: 19,
      step_id: 2,
      disabled: user?.not_filled,
      system: true,
      required: true,
      with_proggress: {
        step: 2
      },
      form_id: 7
    }
  ];
}

function systemSteps(user?: any, product?: any) {
  return [
    {
      title:
        'Are you currently pregnant, planning to become pregnant in the near future or currently breast-feeding?',
      id: 7,
      system: true,
      list: [
        {
          title: 'Yes',
          step_id: null,
          id: 1,
          forbidden: 1,
          content: {
            title: forbiddenTitle,
            text: 'Given that you are pregnant, planning to become pregnant or currently breast-feeding, you are not suitable for treatment with GLP-1 receptor agonsists (Example: Semaglutide, Tirzepatide, ect). Your safety is our number one priority and these medications may cause potential risk to the fetus.',
            text_2: forbiddenTreatmentDisclaimer
          }
        },
        {
          title: 'No',
          step_id: 9,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title: 'Do you have any allergies?',
      id: 9,
      system: true,
      list: [
        {
          title: 'Yes',
          step_id: 10,
          id: 1
        },
        {
          title: 'No',
          step_id: 11,
          id: 2
        }
      ],
      form_id: 12,
      content: {
        body: '<p>Include any allergies to food, dyes, prescriptions or over the counter medicines (e.g. antibiotics, allergy medications), herbs, vitamins, supplements or anything else.</p>'
      }
    },
    {
      title:
        'Please list what you’re allergic to and the reaction that each allergy causes',
      id: 10,
      step_id: 11,
      system: true,
      required: true,
      form_id: 7
    },
    {
      title:
        'Do you have any medical conditions or history of prior surgeries?',
      id: 11,
      system: true,
      list: [
        {
          title: 'Yes',
          step_id: 12,
          id: 1
        },
        {
          title: 'No',
          step_id: 13,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title: 'Please list your medical conditions and any prior surgeries',
      id: 12,
      step_id: 13,
      system: true,
      required: true,
      form_id: 7
    },
    {
      title: 'Do you take any medications, vitamins, or supplements regularly?',
      id: 13,
      system: true,
      list: [
        {
          title: 'Yes',
          step_id: 14,
          id: 1
        },
        {
          title: 'No',
          step_id: 15,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title:
        'Please list any current medicines, vitamins or dietary supplements you take regularly. Please include the dosage. ',
      id: 14,
      step_id: 15,
      system: true,
      required: true,
      form_id: 7
    },
    {
      title:
        'Have you tried or are you currently using any medications to help with weight loss OTHER than GLP-1s?',
      id: 15,
      system: true,
      list: [
        {
          title: 'Yes',
          step_id: 16,
          id: 1
        },
        {
          title: 'No',
          step_id: 17,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title: 'Which medications and were they effective for you? ',
      id: 16,
      step_id: 17,
      system: true,
      required: true,
      form_id: 7
    },
    {
      title:
        'Are you likely to increase your physical fitness and activity, in addition to taking your new medication, if prescribed?',
      id: 17,
      system: true,
      list: optionsYesNoWithText(20, false),
      form_id: 12
    },
    ...dosesSteps(user, product),
    {
      title:
        'Do you have (or have you had) any of the following conditions? Choose All Applicable',
      id: 20,
      step_id: 21,
      system: true,
      list: conditionsSystemOptions,
      form_id: 6
    },
    {
      title:
        'Have you or anyone in your family been diagnosed with thyroid cancer?',
      id: 21,
      system: true,
      list: optionsYesNoWithText(22, false),
      form_id: 12
    },
    {
      title:
        'Do you currently have: Active, Abnormal or Decreased Kidney/Renal Function',
      id: 22,
      list: [
        {
          title: 'Yes',
          step_id: null,
          forbidden: 1,
          content: {
            title: forbiddenTitle,
            text: 'Given that you currently have active, abnormal or decreased kidney/renal function, you are not suitable for treatment with GLP-1 receptor agonsists (Example: Semaglutide, Tirzepatide, ect). Your safety is our number one priority and these medications may cause kidney damage, worsen your condition or reduce kidney functionality.',
            text_2: forbiddenTreatmentDisclaimer
          },
          id: 1
        },
        {
          title: 'No',
          step_id: 23,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title:
        'Do you have any further information you would like the doctor to know?',
      id: 23,
      step_id: 24,
      system: true,
      form_id: 7
    }
  ];
}

// Semaglutide and Tirzepatide products
export function SemaglutideTirzepatideSteps(
  user: any | null,
  isEdit?: boolean,
  product?: any
): any[] {
  if (user?.role === SALES_ROLE_TYPE) {
    return [
      // registration step
      {
        id: 1,
        step_id: 18,
        registration_step: true,
        form_id: 4,
        with_proggress: {
          step: 1
        }
      },
      // product step
      {
        title: 'Which Weight Loss Medication are you interested in?',
        id: 2,
        step_id: 3,
        not_selectable: true,
        no_content: true,
        form_id: 8,
        with_proggress: {
          step: 3
        }
      },
      // plan step
      {
        title: 'Select plan',
        id: 3,
        step_id: 4,
        form_id: 2,
        with_proggress: {
          step: 4
        }
      },
      // address step
      {
        id: 4,
        step_id: 20,
        registration_step: true,
        form_id: 3,
        with_proggress: {
          step: 5
        }
      },
      ...dosesSteps(user, product),
      paymentForm(20)
    ];
  } else {
    return [
      // registration step
      {
        id: 1,
        step_id: 18,
        registration_step: true,
        form_id: 4,
        with_proggress: {
          step: 1
        }
      },
      // product step
      {
        title: 'Which Weight Loss Medication are you interested in?',
        id: 2,
        step_id: 3,
        not_selectable: true,
        no_content: true,
        form_id: 8,
        with_proggress: {
          step: 3
        }
      },
      // plan step
      {
        title: 'Select plan',
        id: 3,
        step_id: 4,
        form_id: 2,
        with_proggress: {
          step: 4
        }
      },
      // password step
      {
        title: null,
        id: 100,
        step_id: 4,
        registration_step: true,
        form_id: 13,
        with_proggress: {
          step: 1
        }
      },
      // address step
      {
        id: 4,
        step_id: 99,
        registration_step: true,
        form_id: 3,
        with_proggress: {
          step: user?.not_filled ? 2 : 5
        }
      },
      {
        title: 'Please Complete Your Medical Intake Form',
        id: 99,
        step_id: 5,
        not_selectable: true,
        content: {
          body: `
        <div class="circle-img">
          <div class="bg" style="background-image: url(assets/images/placeholder-quiz.jpg)"></div>
        </div>
        <p>Please answer some simple medical questions for your medical provider to review</p>
          `
        }
      },
      {
        title: null,
        step_edit_start: true,
        id: 5,
        not_selectable: true,
        step_id: 6,
        form_id: 1
      },
      {
        title: null,
        id: 6,
        not_selectable: true,
        step_id: user?.sex === 'male' ? 9 : 7,
        form_id: 14
      },
      ...systemSteps(user, product),
      {
        title: 'Do you have any questions for the doctor?',
        id: 24,
        step_id: 25,
        system: true,
        form_id: 7
      },
      {
        title: null,
        id: 25,
        step_id: 32,
        end_step: isEdit,
        list: agreeOptions,
        form_id: 6
      },
      {
        title: null,
        id: 32,
        step_id: 33,
        not_selectable: true,
        end_step: user?.order_id ? true : false,
        form_id: 15
      },
      paymentForm(33)
    ];
  }
}

// Followup weightloss
export function FollowUpSteps(product: any): any[] {
  return [
    {
      title: null,
      id: 0,
      step_id: 1,
      form_id: 22,
      not_selectable: true
    },
    {
      title: null,
      id: 1,
      step_id: 9,
      form_id: 21,
      not_selectable: true
    },
    {
      title: 'Do you have any allergies?',
      id: 9,
      system: true,
      list: [
        {
          title: 'Yes',
          step_id: 10,
          id: 1
        },
        {
          title: 'No',
          step_id: 11,
          id: 2
        }
      ],
      form_id: 12,
      content: {
        body: '<p>Include any allergies to food, dyes, prescriptions or over the counter medicines (e.g. antibiotics, allergy medications), herbs, vitamins, supplements or anything else.</p>'
      }
    },
    {
      title:
        'Please list what you’re allergic to and the reaction that each allergy causes',
      id: 10,
      step_id: 11,
      system: true,
      required: true,
      form_id: 7
    },
    {
      title:
        'Do you have any medical conditions or history of prior surgeries?',
      id: 11,
      system: true,
      list: [
        {
          title: 'Yes',
          step_id: 12,
          id: 1
        },
        {
          title: 'No',
          step_id: 13,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title: 'Please list your medical conditions and any prior surgeries',
      id: 12,
      step_id: 13,
      system: true,
      required: true,
      form_id: 7
    },
    {
      title: 'Do you take any medications, vitamins, or supplements regularly?',
      id: 13,
      system: true,
      list: [
        {
          title: 'Yes',
          step_id: 14,
          id: 1
        },
        {
          title: 'No',
          step_id: 15,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title:
        'Please list any current medicines, vitamins or dietary supplements you take regularly. Please include the dosage. ',
      id: 14,
      step_id: 15,
      system: true,
      required: true,
      form_id: 7
    },
    {
      title: 'How are you doing on the current medication?',
      id: 15,
      system: true,
      list: [
        {
          title: 'Well',
          step_id: 6,
          id: 1
        },
        {
          title: 'Not well',
          step_id: 5,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title: `Please tell us more about what you're experiencing`,
      id: 5,
      step_id: 6,
      system: true,
      required: true,
      form_id: 7
    },
    {
      title: null,
      id: 6,
      not_selectable: true,
      step_id: 7,
      form_id: 1
    },
    {
      title: 'Have you had any side effects from the current medication?',
      id: 7,
      system: true,
      list: [
        {
          title: 'No',
          step_id: 17,
          id: 1
        },
        {
          title: 'Yes',
          step_id: 16,
          id: 2
        }
      ],
      form_id: 12
    },
    {
      title: 'Please tell us more about the side effects you are experiencing',
      id: 16,
      step_id: 17,
      system: true,
      required: true,
      form_id: 7
    },
    {
      title:
        'Do you prefer to stay at the same dose, decrease your dose, increase your dose or change the medication?',
      id: 17,
      system: true,
      form_id: 16
    },
    {
      title: 'Please choose the lower dosage you prefer:',
      id: 18,
      step_id: 20,
      doses: product?.doses,
      not_filtered: false,
      form_id: 24,
      system: true
    },
    {
      title: 'Please select the medication',
      id: 19,
      list: [
        {
          title: 'Semaglutide',
          step_id: 208,
          disabled: product?.changed_med_dose === 1,
          doses: 1,
          id: 1
        },
        {
          title: 'Tirzepatide',
          step_id: 209,
          disabled: product?.changed_med_dose === 2,
          doses: 2,
          id: 2
        }
      ],
      form_id: 12,
      system: true
    },
    {
      title: 'What is your requested dose?',
      id: 208,
      step_id: 20,
      steps_remove: [18],
      doses: 1,
      not_filtered: true,
      form_id: 24,
      system: true
    },
    {
      title: 'What is your requested dose?',
      id: 209,
      step_id: 20,
      steps_remove: [18],
      doses: 2,
      not_filtered: true,
      form_id: 24,
      system: true
    },
    {
      title: 'Anything else you’d like to share with your doctor?',
      id: 20,
      step_id: 21,
      system: true,
      form_id: 7
    },
    {
      title: 'Do you have any questions for the doctor?',
      id: 21,
      step_id: 22,
      system: true,
      form_id: 7
    },
    {
      title: null,
      id: 22,
      step_id: product?.with_payment ? 25 : 23,
      list: [
        {
          title:
            'By Checking this Box, you agree to the following: UrWay Health incurs expenses that cannot be recouped once you submit this Follow-Up Checkup Form. Those expenses include, but are not limited to physician consults and  pharmacy related costs. Upon approval by your physician in the form of a prescription sent to the pharmacy, you agree that you will make the next payment in your subscription plan regardless of the timing of that payment. If you do not have a subscription plan you agree that your payment is non-refundable upon approval by your physician in the form of a prescription sent to the pharmacy. If you have a subscription, you can still cancel at any time, however the cancellation of your subscription will not take place until after your next payment is made.',
          id: 1
        }
      ],
      form_id: 6
    },
    {
      title: 'Please select requested dose',
      id: 25,
      step_id: 24,
      form_id: 2
    },
    {
      title: null,
      id: 24,
      form_id: 5,
      end_step: true,
      not_selectable: true
    },
    {
      title: null,
      id: 23,
      end_step: true,
      form_id: 23,
      not_selectable: true
    }
  ];
}
